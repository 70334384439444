body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
  scroll-behavior: smooth;
}

img.resize {
  max-width:100%; /* you can use % */
  height: auto;
}

a { 
  color: inherit;
  text-decoration: none;
} 

@font-face {
  font-family: 'futura_maxi_cg_boldregular';
  src: url('./styles/typography/futura_maxi_cg_bold_regular-webfont.woff2') format('woff2'),
       url('./styles/typography/futura_maxi_cg_bold_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'akkuratbold';
  src: url('./styles/typography/akkurat-bold-opentype_bigfontsite.com-webfont.woff2') format('woff2'),
       url('./styles/typography/akkurat-bold-opentype_bigfontsite.com-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}